<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="visible" @close="handleCancel">
    <a-tabs default-active-key="1"  >
      <a-tab-pane key="1" tab="认证信息">
        <detail-list :col="2">
          <detail-list-item term="用户">{{baseInfo.nickname}}</detail-list-item>
          <detail-list-item term="头像">
            <img-preview   :src="baseInfo.avatar" :size="60"  style="margin-right: 8px;" />
          </detail-list-item>
          <detail-list-item term="性别"><a-tag    color="green">{{baseInfo.sex == 0 ? "保密":(baseInfo.sex == 1 ? "男" : "女")}}</a-tag></detail-list-item>
          <detail-list-item  term="生日"><a-tag    color="red">{{baseInfo.birthday }}</a-tag></detail-list-item>
          <detail-list-item term="年龄">{{baseInfo.age  }}</detail-list-item>
          <detail-list-item term="婚姻状态">
            <dict-tag :options="dict.type['info_marriage']" :value="baseInfo.marriageId"/>
          </detail-list-item>
          <detail-list-item term="民族">
            <dict-tag :options="dict.type['info_nation']" :value="baseInfo.nationId"/>
          </detail-list-item>
          <detail-list-item term="身高(厘米)">{{ baseInfo.height}}</detail-list-item>
          <detail-list-item term="体重(kg)">{{ baseInfo.weight}}</detail-list-item>
          <detail-list-item term="星座">{{ baseInfo.constellation}}</detail-list-item>

          <detail-list-item term="是否体制内"><a-tag    color="green">{{baseInfo.isSystem == 1 ? "是": "否"}}</a-tag></detail-list-item>
          <detail-list-item term="职业">
            <dict-tag :options="dict.type['info_career']" :value="baseInfo.careerId"/>
          </detail-list-item>
          <detail-list-item term="学历">
            <dict-tag :options="dict.type['info_education']" :value="baseInfo.educationId"/>
          </detail-list-item>
          <detail-list-item term="月收入">
            <dict-tag :options="dict.type['info_income']" :value="baseInfo.incomeId"/>
          </detail-list-item>
          <detail-list-item term="现居地"><a-tag    color="green">{{baseInfo.currentAddress  }}</a-tag></detail-list-item>
          <detail-list-item term="户籍地"><a-tag    color="green">{{baseInfo.address  }}</a-tag></detail-list-item>
          <detail-list-item term="购车">
            <dict-tag :options="dict.type['info_buy_car']" :value="baseInfo.buyCarId"/>
          </detail-list-item>
          <detail-list-item term="购房">
            <dict-tag :options="dict.type['info_buy_house']" :value="baseInfo.buyHouseId"/>
          </detail-list-item>
          <detail-list-item term="期望结婚时间">
            <dict-tag :options="dict.type['info_expect_married']" :value="baseInfo.expectMarriedId"/>
          </detail-list-item>
          <detail-list-item term="是否要孩子">
            <dict-tag :options="dict.type['info_except_child']" :value="baseInfo.exceptChildId"/>
          </detail-list-item>
          <detail-list-item term="吸烟">
            <dict-tag :options="dict.type['info_smoke']" :value="baseInfo.smokeId"/>
          </detail-list-item>
          <detail-list-item term="喝酒">
            <dict-tag :options="dict.type['info_drink']" :value="baseInfo.drinkId"/>
          </detail-list-item>
          <detail-list-item term="审核状态"><a-tag color="red">{{baseInfo.approveStatus == 0 ? '待审核':(baseInfo.approveStatus == 1 ? '审核通过' : '审核驳回')  }}</a-tag></detail-list-item>
          <detail-list-item term="审核失败原因">{{baseInfo.approveContent  }}</detail-list-item>

        </detail-list>
        <detail-list :col="1">

          <detail-list-item term="图片">
            <template v-if="baseInfo.photos">
              <img-preview v-for="item in baseInfo.photos.split(',')" :src="item" :size="150"
                           style="margin-right: 8px;" />
            </template>
          </detail-list-item>
        </detail-list>
        <detail-list>
          <detail-list-item term="视频"><video :src="baseInfo.realVideo" width="100%" controls></video></detail-list-item>
        </detail-list>
      </a-tab-pane>
      <a-tab-pane v-if='standardInfo' key="2" tab="择偶信息" force-render>
        <detail-list :col="2">
          <detail-list-item term="年龄(岁)">{{standardInfo.minAge}} —— {{standardInfo.maxAge}}</detail-list-item>

          <detail-list-item term="婚姻状态">
            {{standardInfo.marriage}}
          </detail-list-item>

          <detail-list-item term="身高(厘米)">{{ standardInfo.minHeight}} —— {{standardInfo.maxHeight}}</detail-list-item>
          <detail-list-item term="体重(kg)">{{ standardInfo.minWeight}} —— {{standardInfo.maxWeight}}</detail-list-item>

          <detail-list-item term="学历">
            {{standardInfo.education}}
          </detail-list-item>
          <detail-list-item term="月收入">
            {{standardInfo.income}}
          </detail-list-item>
          <detail-list-item term="职业"> {{standardInfo.career  }}</detail-list-item>
          <detail-list-item term="现居地"><a-tag    color="green">{{baseInfo.currentAddress  }}</a-tag></detail-list-item>

          <detail-list-item term="是否要孩子">
            {{standardInfo.exceptChild  }}
          </detail-list-item>
          <detail-list-item term="吸烟">
            {{standardInfo.smoke  }}
          </detail-list-item>
          <detail-list-item term="喝酒">
            {{standardInfo.drink  }}
          </detail-list-item>
          <detail-list-item term="是否有小孩"><a-tag    color="green">{{standardInfo.isHaveSon == 1 ? "是": "否"}}</a-tag></detail-list-item>
        </detail-list>
      </a-tab-pane>

    </a-tabs>
  </a-drawer>




</template>

<script>

  import DetailList from '@/components/tools/DetailList'
  import ImgPreview from "@/components/crud/table/ImgPreview.vue";
  import dictTag from '@/components/DictTag/index2.vue'
  const DetailListItem = DetailList.Item
  import { getEditApply } from '@/api/user/editApply'
  export default {
    name: "HouseInfo",
    components: {
      ImgPreview,
      DetailList,dictTag,
      DetailListItem
    },
    dicts: ['info_marriage','info_nation','info_career','info_education','info_income','info_buy_car','info_buy_house','info_expect_married',
      'info_except_child','info_smoke','info_drink','info_interest'  ],
    data () {
      return {
        title:'',
        width:1500,
        visible: false,
        disableSubmit: false,
        lessorShow: false,
        lesseeShow: false,

        query: {},
        model: { },
        baseInfo:{},
        standardInfo:{},
        addressList:[],


      }
    },
    created() {

    },

    methods: {
      initValue(record){
        getEditApply({"id":record.id}).then(response => {
          this.baseInfo = response.data
          if(this.baseInfo.standardInfo){
            this.standardInfo = this.baseInfo.standardInfo
          }
        })
      },

      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleCancel () {
        this.close()
      },
      back(){
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="less" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {

    }
    .status-list {
      text-align: left;
    }
  }
</style>